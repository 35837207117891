/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

const mySwiper = new Swiper('.swiper-container', {
	// Optional parameters
	autoHeight: false,
	direction: 'horizontal',
	loop: true,
	centeredSlides: true,
	lazy: true,
	spaceBetween: 30,
	slidesPerView: 1,
	slidesToScroll: 1,
	breakpoints: {
		// when window width is >= 320px
		768: {
			slidesPerView: 2
		},
		1024: {
			slidesPerView: 3
		},
	},


	// If we need pagination
	pagination: {
		el: '.swiper-pagination',
		clickable: true
	},

	// Navigation arrows
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	}
});

/* Swiper
**************************************************************/
// var swiperPort = Swiper;
// var swiperEv = Swiper;
// var swiperSt = Swiper;
var initPortfolio = false;
var initSteps = false;
var initEvents = false;

function swiperPortfolio() {
	let mobile = window.matchMedia('(min-width: 0px) and (max-width: 1024px)');
	let tablet = window.matchMedia('(min-width: 769px) and (max-width: 1024px)');
	let desktop = window.matchMedia('(min-width: 1025px)');

	// Enable (for mobile)
	if (mobile.matches) {
		if (!initPortfolio) {
			initPortfolio = true;
			swiperPort = new Swiper('.swiper-portfolio', {
				slidesPerView: 'auto',
				centeredSlides: true,
				loopedSlides: 4,
				loop: true,
				spaceBetween: 10,
				direction: 'horizontal',

				pagination: {
					el: '.swiper-pagination',
					clickable: true
				}
			});
		}

	}

	// Disable (for tablet)

	// Disable (for desktop)
	else if (desktop.matches) {

		if (typeof swiperPort !== 'undefined') {
			console.log('deze');
			swiperPort.destroy();
		}
		initPortfolio = false;
	}
}

function swiperEvents() {
	let mobile = window.matchMedia('(min-width: 0px) and (max-width: 1024px)');
	let tablet = window.matchMedia('(min-width: 769px) and (max-width: 1024px)');
	let desktop = window.matchMedia('(min-width: 1025px)');

	// Enable (for mobile)
	if (mobile.matches) {
		if (!initEvents) {
			initEvents = true;
			swiperEv = new Swiper('.swiper-events', {
				slidesPerView: 'auto',
				centeredSlides: true,
				loopedSlides: 4,
				loop: true,
				spaceBetween: 10,
				direction: 'horizontal',
				pagination: {
					el: '.swiper-pagination',
					clickable: true
				}
			});
		}

	}

	// Disable (for tablet)
	// Disable (for desktop)
	else if (desktop.matches) {
		if (typeof swiperEv !== 'undefined') {
			console.log('deze');
			swiperEv.destroy();
		}
		initEvents = false;
	}
}


function swiperSteps() {
	let mobile = window.matchMedia('(min-width: 0px) and (max-width: 1024px)');
	let tablet = window.matchMedia('(min-width: 769px) and (max-width: 1024px)');
	let desktop = window.matchMedia('(min-width: 1025px)');

	// Enable (for mobile)
	if (mobile.matches) {
		if (!initSteps) {
			initSteps = true;
			swiperSt = new Swiper('.swiper-steps', {
				slidesPerView: 'auto',
				centeredSlides: true,
				loopedSlides: 4,
				loop: true,
				spaceBetween: 10,
				direction: 'horizontal',
				pagination: {
					el: '.swiper-pagination',
					clickable: true
				}
			});
		}

	}

	// Disable (for desktop)
	else if (desktop.matches) {
		if (typeof swiperSt !== 'undefined') {
			console.log('deze');
			swiperSt.destroy();
		}
		initSteps = false;
	}
}





/* On Load
**************************************************************/
window.addEventListener('load', function () {
	swiperEvents();
	swiperPortfolio();
	swiperSteps();
});

/* On Resize
**************************************************************/
window.addEventListener('resize', function () {
	swiperEvents();
	swiperPortfolio();
	swiperSteps();
});



document.addEventListener('DOMContentLoaded', () => {

	// Sticky header
	const logoContainer = document.querySelector('header')
	var scrollPosition = window.scrollY;

	window.addEventListener('scroll', function () {

		scrollPosition = window.scrollY;

		if (scrollPosition >= 30) {
			logoContainer.classList.add('is-sticky');
		} else {
			logoContainer.classList.remove('is-sticky');
		}

	});
	// Get all "navbar-burger" elements
	const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

	// Check if there are any navbar burgers
	if ($navbarBurgers.length > 0) {

		// Add a click event on each of them
		$navbarBurgers.forEach(el => {
			el.addEventListener('click', () => {

				// Get the target from the "data-target" attribute
				const target = el.dataset.target;
				const $target = document.getElementById(target);

				// Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
				el.classList.toggle('is-active');
				$target.classList.toggle('is-active');
			});
		});
	}

});



(function ($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function () {

			},
			finalize: function () {

			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

	animateAnchor = function () {

		if ($(window.location.hash).length > 0) {
			$('html,body').animate({
				scrollTop: $(window.location.hash).offset().top - 150
			}, 1000);
		}

		$('a[href^="#"], .navbar-scroll').on('click touchstart', function (e) {
			var the_id = $(this).attr("href").split('#')[1];

			if ($('#' + the_id).length !== 0) {
				e.preventDefault();
			}

			var offset = $('#' + the_id).offset().top;
			$('html, body').animate({
				scrollTop: offset - 150
			}, 1000
			);

			$('.navbar-burger').removeClass('is-active');
			$('.navbar-menu').removeClass('is-active');
			return false;
		});
	};

})(jQuery);


